<template>
  <cui-acl  :roles="['underDevelopment']" redirect>
    <h2>Bonus</h2>
    <!-- Display payslips here -->
    <oldBonus v-if="!newBonus"/>
    <a-divider />
    <a-button @click="newBonus = !newBonus">Ny bonus</a-button>
    <newBonus v-if="newBonus" class="mt-3"/>
  </cui-acl>
</template>

<script>
import oldBonus from '@/components/custom/payroll/oldBonus'
import newBonus from '@/components/custom/payroll/newBonus'
import cuiAcl from '@/components/cleanui/system/ACL'

export default {
  components: {
    newBonus,
    oldBonus,
    cuiAcl,
  },
  data() {
    return {
      newBonus: false,
      loading: false,
    }
  },
}
</script>
