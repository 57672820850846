<template>
  <div>
    <h5 class="text-black mt-4 mb-3">
      <strong>Ny Bonus</strong>
    </h5>
    <div class="card">
      <div class="card-body">
        <a-form :form="form" layout="vertical">
          <a-form-item label="Type">
            <a-select v-model="transaction.type" placeholder="Velg type">
              <a-select-option value="bonus">Bonus</a-select-option>
              <a-select-option value="netDeduction">Trekk</a-select-option>
              <a-select-option value="grossDeduction">Trekk Bruttolønn</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="Beløp">
            <a-input-number v-model="transaction.amount" :parser="value => value.replace(/\$\s?|(,*)/g, '')" />
          </a-form-item>
          <a-form-item label="Ansatt">
            <a-select
              v-model="transaction.employee"
              placeholder="Velg ansatt"
              show-search
              :filter-option="filterOption"
              option-filter-prop="children"
              >
              <a-select-option v-for="employee in employees" :key="employee.employeeID" :value="employee.employeeID">
                {{ employee.employeeID }} - {{ employee.fullName }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="Dato">
            <a-date-picker v-model="transaction.date" />
          </a-form-item>
          <a-form-item label="Kommentar">
            <a-input v-model="transaction.comment" />
          </a-form-item>
          <a-form-item label="Periode">
            <a-month-picker v-model="transaction.period"/>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="createTransaction">
              Opprett
            </a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { firestore } from '@/services/firebase'
import { openNotification } from '@/services/powerAutomate'

export default {
  data() {
    return {
      form: this.$form.createForm(this),
      employees: [],
      wageTypes: [],
      transaction: {
        type: 'bonus',
        amount: 0,
        count: 1,
        employeeID: '',
        date: String(moment().format('YYYY-MM-DD')),
        comment: '',
        period: moment().format('YYYY-MM'),
      },
    }
  },
  mounted() {
    this.fetchEmployees()
  },
  methods: {
    async createTransaction() {
      const transaction = this.transaction
      const employee = this.employees.find((employee) => employee.employeeID === transaction.employee)
      transaction.period = String(moment(transaction.period).format('YYYYMM'))
      transaction.registeredBy = this.$store.state.user.employeeID
      transaction.registeredByName = this.$store.state.user.name
      transaction.requestedOn = String(moment().format('YYYY-MM-DD'))
      transaction.status = 'pending approval'
      transaction.employeeName = employee.fullName
      transaction.company = employee.companies[0]
      console.log(transaction)
      transaction.id = await firestore.collection('payrollTransactions').add(transaction)
      console.log(transaction)
      await this.createTask(transaction)
      openNotification('Bonus sendt til godkjenning')
      this.transaction = {
        type: 'bonus',
        amount: 0,
        count: 1,
        employeeID: '',
        date: String(moment().format('YYYY-MM-DD')),
        comment: '',
        period: '',
      }
    },
    async createTask(transaction) {
      const task = {
        date: String(moment().format('YYYY-MM-DDTHH:mm')),
        type: 'approve transaction',
        status: 'to-do',
        title: 'Ny Bonus til godkjenning',
        description: `${transaction.registeredByName} har søkt om bonus til ${transaction.employeeName} på ${transaction.amount} kr`,
        callbacks: [
          {
            fn: 'approveTransaction',
            name: 'Godkjenn',
            type: 'CompleteTask',
            data: {
              newStatus: 'approved',
              transactionID: transaction.id,
            },
          },
          {
            fn: 'approveTransaction',
            name: 'Avvis',
            type: 'DeclineTask',
            data: {
              newStatus: 'declined',
              transactionID: transaction.id,
            },
          },
        ],
      }
      await firestore.collection('tasks').add(task)
    },
    fetchEmployees() {
      this.employees = []
      firestore.collection('users')
        .where('companies', 'array-contains-any', this.$store.state.user.companies)
        .orderBy('fullName')
        .get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const employee = {
              fullName: doc.data().fullName,
              department: doc.data().department,
              employeeID: doc.data().employeeID,
              companies: doc.data().companies,
            }
            this.employees.push(employee)
          })
        })
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
  },
}
</script>

<style>

</style>
