<template>
  <div>
    <!-- Filters -->
    <bonus-filter @filter-change="handleFilterChange" />
    <!-- Display imports -->
    <a-spin :spinning="loading">
      <a-table bordered :data-source="dataSource" :columns="columns">
        <template slot="operation" slot-scope="text, record">
          <a-popconfirm
            v-if="dataSource.length && record.status!= 'blocked'"
            title="Sure to delete?"
            @confirm="() => onDelete(record.id)"
          >
            <a href="javascript:;">Slett</a>
          </a-popconfirm>
        </template>
      </a-table>
    </a-spin>
  </div>
</template>

<script>
import { firestore } from '@/services/firebase'
import { openNotification } from '@/services/powerAutomate'

import BonusFilter from '@/components/custom/payroll/filters'
import moment from 'moment'

export default {
  components: {
    BonusFilter,
  },
  data() {
    return {
      dataSource: [],
      projects: [],
      loading: false,
      columns: [
        {
          title: 'Dato',
          dataIndex: 'date',
        },
        {
          title: 'Registrert av',
          dataIndex: 'registeredBy',
        },
        {
          title: 'Prosjekt',
          dataIndex: 'project',
        },
        {
          title: 'Beløp',
          dataIndex: 'amount',
        },
        {
          title: 'Beskrivelse',
          dataIndex: 'description',
        },
        {
          title: 'Status',
          dataIndex: 'status',
        },
        {
          title: 'Slett',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
        },
      ],
    }
  },
  mounted() {
    firestore.collection('settings').doc('projects').collection('projects')
      .where('company', '==', 'Skycall')
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          const project = {
            value: doc.id,
            label: doc.data().name,
          }
          this.projects.push(project)
        })
      })
    // this.getData()
  },
  methods: {
    moment,
    handleFilterChange(filters) {
      console.log(filters)
      this.getData(filters)
      // Fetch payslips based on the selected period
    },
    getData(filters) {
      firestore.collection('payrollTransactions')
        .where('type', '==', 'bonus')
        .where('period', '==', filters.period)
        .get().then(snapshot => {
          this.count = 0
          snapshot.forEach(doc => {
            const data = doc.data()
            data.id = doc.id
            this.dataSource.push(data)
            this.count += 1
          })
          console.log(this.dataSource)
        })
    },
    async onDelete(transactionId) {
      this.loading = true
      const dataSource = [...this.dataSource]
      const target = dataSource.find((obj) => obj.id === transactionId)

      if (target.status !== 'blocked') {
        await firestore.collection('payrollTransactions').doc(transactionId).delete()
        this.dataSource = dataSource.filter((obj) => obj.id !== transactionId)

        this.$forceUpdate()
        openNotification('Bonus slettet')
      }
      this.loading = false
    },
  },
}
</script>

<style>

</style>
